import { Button, ListItemText, Paper, styled } from '@material-ui/core';

export const SelectionPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(2),
}));

export const SelectionButton = styled(Button)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    borderColor: theme.palette.getContrastText(theme.palette.primary.main),
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
    borderStyle: 'solid',
    borderWidth: '3px 0 3px 3px',
    color: theme.palette.getContrastText(theme.palette.primary.main),
    padding: theme.spacing(2),
    position: 'fixed',
    right: '-38px',
    transform: 'translateY(-50%)',
    transition: 'right 255ms',
    top: '50%',
    zIndex: theme.zIndex.speedDial,
    '& .MuiBadge-badge': {
        right: '31px',
        transition: 'right 255ms',
    },
    '&:hover': {
        right: 0,
        '& .MuiBadge-badge': {
            right: 0,
        },
    },
}));

export const RentalListItem = styled(ListItemText)(({ theme }) => ({
    paddingRight: theme.spacing(3),
    maxWidth: '100%',
}));
