import { AxiosPromise } from 'axios';
import { normalizeUri, requestApi } from 'services/axios';
import { createResourceApi, DEFAULT_FETCHED_ITEMS_PER_AGE } from 'resources/ResourceApi';
import { CollectionJsonLD, CreditNoteApiItem, CreditNotePaymentApiItem, CreditNoteRefundApiItem } from 'typings/api';
import { RefundFormValues } from 'typings/forms';

const creditNotePayments = (
    creditNote: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<CreditNotePaymentApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('credit_notes', creditNote)}/payments`,
        params: queryParameters,
    });
};

const creditNoteRefunds = (
    creditNote: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<CreditNoteRefundApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('credit_notes', creditNote)}/refunds`,
        params: queryParameters,
    });
};

const creditNoteRefundPayment = (creditNoteId: string, data: RefundFormValues): AxiosPromise<CreditNoteApiItem> =>
    requestApi({
        url: `${normalizeUri('credit_notes', creditNoteId)}/refund-payment`,
        method: 'PATCH',
        headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/merge-patch+json',
        },
        data,
    });

const creditNotePayment = (
    creditNoteId: string,
    booking: string,
    data: RefundFormValues,
): AxiosPromise<CreditNoteApiItem> =>
    requestApi({
        url: `${normalizeUri('credit_notes', creditNoteId)}/credit-note-payment`,
        method: 'PATCH',
        headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/merge-patch+json',
        },
        data: {
            ...data,
            booking,
        },
    });

export default {
    ...createResourceApi<CreditNoteApiItem>('credit_notes'),
    creditNotePayments,
    creditNoteRefunds,
    creditNoteRefundPayment,
    creditNotePayment,
};
