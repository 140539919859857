import { paths, routes } from 'pages/manager/routes';
import api from 'resources/manager/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/manager/selectors';
import { actions } from 'store/manager/slice';
import { ManagerApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<ManagerApiItem> = {
    name: 'manager',
    translationKey: 'manager',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/manager')),
};

export default resource;
