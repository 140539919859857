import lazyWithRetry from 'services/lazyWithRetry';
import { Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const SeasonListPage = lazyWithRetry(() => import('./List'));
const SeasonAddPage = lazyWithRetry(() => import('./Add'));
const SeasonEditPage = lazyWithRetry(() => import('./Edit'));

export const paths = {
    LIST: '/season',
    ADD: '/season/add',
    EDIT: '/season/:id/edit',
};

export const routes: RouteCollection = {
    list: {
        exact: true,
        path: paths.LIST,
        component: SeasonListPage,
        roles: [Roles.BASE_USER],
    },
    add: {
        exact: true,
        path: paths.ADD,
        component: SeasonAddPage,
        roles: [Roles.TRADER, Roles.WEBMASTER],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: SeasonEditPage,
        roles: [Roles.TRADER, Roles.WEBMASTER],
    },
};
