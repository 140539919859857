import lazyWithRetry from 'services/lazyWithRetry';
import { Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const ListPage = lazyWithRetry(() => import('./List'));
const CreateFromEnquiryPage = lazyWithRetry(() => import('./AddFromEnquiry'));
const AddPage = lazyWithRetry(() => import('./Add'));
const EditPage = lazyWithRetry(() => import('./Edit'));
const ShowPage = lazyWithRetry(() => import('./Show'));

export const paths = {
    LIST: '/booking/list',
    CREATE_FROM_ENQUIRY: '/booking/create-from-enquiry/:enquiryId/:rentalId',
    ADD: '/booking/add',
    ADD_STEP_2: '/booking/add/step2',
    ADD_STEP_3: '/booking/add/step3',
    EDIT: '/booking/:id/edit',
    SHOW: '/booking/:id/show',
};

export const routes: RouteCollection = {
    list: {
        exact: false,
        path: paths.LIST,
        component: ListPage,
        roles: [Roles.BASE_USER],
    },
    createFromEnquiry: {
        exact: false,
        path: paths.CREATE_FROM_ENQUIRY,
        component: CreateFromEnquiryPage,
        roles: [Roles.TRADER],
    },
    add: {
        exact: false,
        path: paths.ADD,
        component: AddPage,
        roles: [Roles.TRADER],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: EditPage,
        roles: [Roles.TRADER],
    },
    show: {
        exact: true,
        path: paths.SHOW,
        component: ShowPage,
        roles: [Roles.TRADER],
    },
};
