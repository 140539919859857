import { QueryClient } from 'react-query';
import { CacheKeys } from 'typings/enums';

export const invalidateAdvancedSearchCache = (queryClient: QueryClient, callback?: () => any): void => {
    Promise.all([
        queryClient.invalidateQueries(CacheKeys.ADVANCED_SEARCH_PRICES),
        queryClient.invalidateQueries(CacheKeys.ADVANCED_SEARCH_UNAVAILABILITIES),
        queryClient.invalidateQueries(CacheKeys.ADVANCED_SEARCH),
    ]).then(callback && callback());
};

export const invalidateAdvancedSearchCacheWithRental = (
    queryClient: QueryClient,
    rentalIri: string,
    callback?: () => any,
): void => {
    Promise.all([
        queryClient.invalidateQueries({
            predicate: (query) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const iri = query.queryKey[1]?.item ?? '';

                return CacheKeys.ADVANCED_SEARCH_PRICES === query.queryKey[0] && iri === rentalIri;
            },
        }),
        queryClient.invalidateQueries({
            predicate: (query) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const iri = query.queryKey[1]?.item ?? '';

                return CacheKeys.ADVANCED_SEARCH_UNAVAILABILITIES === query.queryKey[0] && iri === rentalIri;
            },
        }),
        queryClient.invalidateQueries(CacheKeys.ADVANCED_SEARCH),
    ]).then(callback && callback());
};
