import LuxonUtils from '@date-io/luxon';
import { NoSsr } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import ErrorBoundary from 'components/Layout/ErrorBoundary';
import { FullPageLoader } from 'components/Layout/FullPageLoader/FullPageLoader';
import React, { Suspense } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { store } from 'store';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

Sentry.init({
    dsn: 'https://a950ba556230464cb5d3e22e4dce74a3@o1055256.ingest.sentry.io/6041225',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    sampleRate: process.env.NODE_ENV !== 'production' ? 0 : 1,
    environment: process.env.NODE_ENV !== 'production' ? 'dev' : 'prod',
    ignoreErrors: ['Request failed with status code 403', 'Request failed with status code 401'],
});

ReactDOM.render(
    <Sentry.ErrorBoundary fallback={ErrorBoundary}>
        <NoSsr>
            <DndProvider backend={HTML5Backend}>
                <Suspense fallback={<FullPageLoader />}>
                    <Provider store={store}>
                        <PersistGate persistor={persistStore(store)}>
                            <BrowserRouter>
                                <MuiPickersUtilsProvider utils={LuxonUtils} locale="fr">
                                    <App />
                                </MuiPickersUtilsProvider>
                            </BrowserRouter>
                        </PersistGate>
                    </Provider>
                </Suspense>
            </DndProvider>
        </NoSsr>
    </Sentry.ErrorBoundary>,
    document.getElementById('root'),
);

reportWebVitals(console.log);
serviceWorker.unregister();
