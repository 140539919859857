import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { ManagerState } from 'typings/state';

export const initialState: ManagerState = resourceInitialState();

const slice = createResourceSlice({
    name: 'manager',
    initialState,
});

export const manager = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
