import { paths, routes } from 'pages/poi/routes';
import api from 'resources/poi/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/poi/selectors';
import { actions } from 'store/poi/slice';
import { PointOfInterestApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<PointOfInterestApiItem> = {
    name: 'poi',
    translationKey: 'poi',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/poi')),
};

export default resource;
