/* eslint-disable @typescript-eslint/naming-convention */
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'localforage';
import { createMigrate, FLUSH, PAUSE, PERSIST, persistReducer, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import { furnishing } from 'store/furnishing/slice';
import migrations from 'store/migrations';
import { accounting } from './accounting/slice';
import { bed } from './bed/slice';
import { booking } from './booking/slice';
import { cancelReason } from './cancelReason/slice';
import { competitor } from './competitor/slice';
import { creditNote } from './creditNote/slice';
import { customer } from './customer/slice';
import { enquiry } from './enquiry/slice';
import { highlight } from './highlight/slice';
import { layout } from './layout/slice';
import { locale } from './locale/slice';
import { location } from './location/slice';
import { manager } from './manager/slice';
import { origin } from './origin/slice';
import { owner } from './owner/slice';
import { partner } from './partner/slice';
import { paymentMode } from './paymentMode/slice';
import { poi } from './poi/slice';
import { rental } from './rental/slice';
import { request } from './request/slice';
import { requestContact } from './requestContact/slice';
import { roomType } from './roomType/slice';
import { season } from './season/slice';
import { team } from './team/slice';
import { user } from './user/slice';

const serializableCheck = {
    serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
    immutableCheck: { warnAfter: 100 },
};

export const resourceMapping = {
    accounting: 'accounting',
    bookings: 'booking',
    competitors: 'competitor',
    customers: 'customer',
    managers: 'manager',
    owners: 'owner',
    partners: 'partner',
    seasons: 'season',
    users: 'user',
    beds: 'bed',
    highlights: 'highlight',
    locations: 'location',
    point_of_interests: 'poi',
    rentals: 'rental',
    room_types: 'roomType',
    furnishings: 'furnishing',
    payment_modes: 'paymentMode',
    enquiries: 'enquiry',
    origins: 'origin',
    creditNotes: 'creditNote',
    requestContact: 'requestContact',
    cancelReason: 'cancelReason',
};

export const sharedResources = ['beds', 'furnishings', 'locations', 'point_of_interests', 'room_types'];

const reducers = {
    accounting,
    bed,
    booking,
    competitor,
    customer,
    highlight,
    furnishing,
    layout,
    location,
    locale,
    manager,
    owner,
    partner,
    paymentMode,
    poi,
    rental,
    request,
    roomType,
    season,
    team,
    user,
    enquiry,
    origin,
    creditNote,
    requestContact,
    cancelReason,
};

const persistConfig = {
    key: 'root',
    version: 4,
    storage,
    migrate: createMigrate(migrations, { debug: false }),
};

export const combinedReducers = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, combinedReducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(serializableCheck),
    devTools: process.env.NODE_ENV !== 'production',
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
