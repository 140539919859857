import NotFound from 'components/NotFound';
import lazyWithRetry from 'services/lazyWithRetry';
import { Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const ListPage = lazyWithRetry(() => import('./List'));
const ShowPage = lazyWithRetry(() => import('./Show'));

export const paths = {
    LIST: '/request-contact',
    ADD: '/request-contact/add',
    EDIT: '/request-contact/:id/edit',
    SHOW: '/request-contact/:id/show',
};

export const routes: RouteCollection = {
    list: {
        exact: true,
        path: paths.LIST,
        component: ListPage,
        roles: [Roles.TRADER],
    },
    add: {
        exact: true,
        path: paths.ADD,
        component: NotFound,
        roles: [Roles.TRADER],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: NotFound,
        roles: [Roles.TRADER],
    },
    show: {
        exact: true,
        path: paths.SHOW,
        component: ShowPage,
        roles: [Roles.TRADER],
    },
};
