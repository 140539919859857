import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import createResourceListSelectors from 'store/ResourceListSelectors';
import { BookingListType, OrNull } from 'typings/shared';
import { BookingAddBill, BookingAddInfos, BookingState, ResourceListSelectors, StateResource } from 'typings/state';

const getResourceState = (state: RootState): BookingState => state.booking;

const bookingSelectors: ResourceListSelectors = createResourceListSelectors(
    (state: RootState): StateResource => ({
        list: state.booking[`list${state.booking.currentList}` as const],
    }),
);

const selectFormAddCurrentStep = createSelector<RootState, BookingState, number>(
    getResourceState,
    (state: BookingState) => state.add.currentStep,
);

const selectFormAddInfosStep = createSelector<RootState, BookingState, BookingAddInfos>(
    getResourceState,
    (state: BookingState) => state.add.infosStep,
);

const selectFormAddBillChoiceStep = createSelector<RootState, BookingState, boolean>(
    getResourceState,
    (state: BookingState) => state.add.customerBilled,
);

const selectFormAddSBillStep = createSelector<RootState, BookingState, BookingAddBill>(
    getResourceState,
    (state: BookingState) => state.add.bill,
);

const selectCurrentList = createSelector<RootState, BookingState, BookingListType>(
    (state: RootState): BookingState => state.booking,
    (state: BookingState) => state.currentList,
);

const selectFromEnquiry = createSelector<RootState, BookingState, OrNull<string>>(
    (state: RootState): BookingState => state.booking,
    (state: BookingState) => state.add.enquiry,
);

const extraSelectors = {
    selectFormAddCurrentStep,
    selectFormAddInfosStep,
    selectFormAddBillChoiceStep,
    selectFormAddSBillStep,
    selectCurrentList,
    selectFromEnquiry,
};

const selectors = { ...bookingSelectors, ...extraSelectors };
export default selectors;
