import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MUISortOptions } from 'mui-datatables';
import { resourceInitialState } from 'store/ResourceSlice';
import { BookingListType, OrNull } from 'typings/shared';
import { BookingAddInfos, BookingAddState, BookingState } from 'typings/state';

export const MAX_STEP_FORM_BOOKING_ADD = 2;

const initialInfosStep: BookingAddInfos = {
    rental: '',
    checkIn: '',
    checkOut: '',
    guests: 2,
    origin: '',
    occupantCounts: {
        adults: 2,
        children: 0,
        babies: 0,
        animals: 0,
    },
    mandate: '',
};

const initialBookingAddState: BookingAddState = {
    currentStep: 0,
    customerBilled: true,
    infosStep: initialInfosStep,
    bill: { customer: '', partner: '', customerFullName: '' },
    enquiry: null,
};

const { list: listState } = resourceInitialState();

export const stateByType = {
    option: ['option'],
    confirmed: ['confirmed', 'paid'],
    due: ['owner_to_pay', 'partner_to_pay'],
    archived: ['archived', 'cancel_refund', 'cancel_report', 'canceled'],
};

export const initialState: BookingState = {
    listOption: { ...listState, filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.option] },
    listConfirmed: { ...listState, filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.confirmed] },
    listDue: {
        ...listState,
        filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.due],
    },
    listArchived: {
        ...listState,
        filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.archived],
    },
    currentList: 'Option',
    add: initialBookingAddState,
};

const slice = createSlice({
    name: 'booking',
    initialState,
    reducers: {
        setCurrentPage: (state: BookingState, action: PayloadAction<number>): void => {
            state[`list${state.currentList}` as const].currentPage = action.payload;
        },
        setItemsPerPage: (state: BookingState, action: PayloadAction<number>): void => {
            if (state[`list${state.currentList}` as const].itemsPerPage !== action.payload) {
                state[`list${state.currentList}` as const].currentPage = 1;
            }
            state[`list${state.currentList}` as const].itemsPerPage = action.payload;
        },
        setSearch: (state: BookingState, action: PayloadAction<OrNull<string>>): void => {
            if (state[`list${state.currentList}` as const].search !== action.payload) {
                state[`list${state.currentList}` as const].currentPage = 1;
            }
            state[`list${state.currentList}` as const].search = action.payload;
        },
        setFilters: (state: BookingState, action: PayloadAction<Array<any>>): void => {
            if (state[`list${state.currentList}` as const].filters !== action.payload) {
                state[`list${state.currentList}` as const].currentPage = 1;
            }
            state[`list${state.currentList}` as const].filters = action.payload;
        },
        setSort: (state: BookingState, action: PayloadAction<MUISortOptions>): void => {
            state[`list${state.currentList}` as const].sort = action.payload;
        },
        setColumnVisibility: (
            state: BookingState,
            action: PayloadAction<{ column: string; visible: boolean }>,
        ): void => {
            state[`list${state.currentList}` as const].columns[action.payload.column] = action.payload.visible;
        },
        setCurrentList: (state: BookingState, action: PayloadAction<BookingListType>): void => {
            state.currentList = action.payload;
        },
        setStep: (state: BookingState, action: PayloadAction<number>): void => {
            state.add.currentStep = action.payload;
        },
        resetAddState: (state: BookingState, _action: PayloadAction<boolean>): void => {
            state.add = initialBookingAddState;
        },
        setDataInfosForm: (state: BookingState, action: PayloadAction<BookingAddInfos>): void => {
            state.add.infosStep = action.payload;
        },
        setDataBillChoiceForm: (state: BookingState, action: PayloadAction<boolean>): void => {
            state.add.customerBilled = action.payload;
        },
        setAddStateFromEnquiry: (state: BookingState, action: PayloadAction<BookingAddState>): void => {
            state.add = action.payload;
        },
    },
});

export const booking = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
