import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { PointOfInterestState } from 'typings/state';

export const initialState: PointOfInterestState = resourceInitialState();

const slice = createResourceSlice({
    name: 'poi',
    initialState,
});

export const poi = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
