import lazyWithRetry from 'services/lazyWithRetry';
import { Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const PaymentModeListPage = lazyWithRetry(() => import('./List'));
const PaymentModeAddPage = lazyWithRetry(() => import('./Add'));
const PaymentModeEditPage = lazyWithRetry(() => import('./Edit'));

export const paths = {
    LIST: '/payment-mode',
    ADD: '/payment-mode/add',
    EDIT: '/payment-mode/:id/edit',
};

export const routes: RouteCollection = {
    list: {
        exact: true,
        path: paths.LIST,
        component: PaymentModeListPage,
        roles: [Roles.BASE_USER],
    },
    add: {
        exact: true,
        path: paths.ADD,
        component: PaymentModeAddPage,
        roles: [Roles.MANAGER],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: PaymentModeEditPage,
        roles: [Roles.MANAGER],
    },
};
