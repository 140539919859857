import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { PaymentModeState } from 'typings/state';

export const initialState: PaymentModeState = resourceInitialState();

const slice = createResourceSlice({
    name: 'paymentMode',
    initialState,
});

export const paymentMode = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
