import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { CustomerState } from 'typings/state';

export const initialState: CustomerState = resourceInitialState();

const slice = createResourceSlice({
    name: 'customer',
    initialState,
});

export const customer = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
