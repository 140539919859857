import { AxiosPromise } from 'axios';
import { createResourceApi } from 'resources/ResourceApi';
import { normalizeUri, requestApi } from 'services/axios';
import { TeamApiItem } from 'typings/api';

const clearCache = (team: string): AxiosPromise<any> =>
    requestApi({
        url: `${normalizeUri('teams', team)}/cache_clear`,
        method: 'post',
        data: {},
    });

export default {
    ...createResourceApi<TeamApiItem>('teams'),
    clearCache,
};
