import React from 'react';
import { useTranslation } from 'react-i18next';
import { NotFoundElement } from './styled';

export const NotFound: React.FC = () => {
    const { t } = useTranslation('errors');

    return (
        <NotFoundElement variant="h3" color="primary">
            {t('errors:not_found')}
        </NotFoundElement>
    );
};
