import { paths, routes } from 'pages/highlight/routes';
import api from 'resources/highlight/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/highlight/selectors';
import { actions } from 'store/highlight/slice';
import { HighlightApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<HighlightApiItem> = {
    name: 'highlight',
    translationKey: 'highlight',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/highlight')),
};

export default resource;
