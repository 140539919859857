import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import createResourceListSelectors from 'store/ResourceListSelectors';
import { EnquirySelection, EnquiryState, ResourceListSelectors } from 'typings/state';

const getResourceState = (state: RootState): EnquiryState => state.enquiry;

const selectSelection = createSelector<RootState, EnquiryState, EnquirySelection>(
    getResourceState,
    (state: EnquiryState) => state.selection,
);

const selectors: ResourceListSelectors & { selectSelection: typeof selectSelection } = {
    ...createResourceListSelectors(getResourceState),
    selectSelection,
};

export default selectors;
