import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { LocationState } from 'typings/state';

export const initialState: LocationState = resourceInitialState();

const slice = createResourceSlice({
    name: 'location',
    initialState,
});

export const location = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
