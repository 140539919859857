import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FallbackRender } from '@sentry/react/dist/errorboundary';
import React, { ReactElement } from 'react';

export const ErrorBoundary: FallbackRender = ({ error, resetError }): ReactElement => (
    <Snackbar open={!!error} autoHideDuration={15000} onClose={(): void => resetError()}>
        <Alert onClose={(): void => resetError()} severity="error">
            Une erreur est survenue et a été envoyée à l'équipe de développement.
        </Alert>
    </Snackbar>
);
