import AuthenticatedLayout from 'components/Security/AuthenticatedLayout';
import lazyWithRetry from 'services/lazyWithRetry';
import { RouteWithLayout } from 'typings/shared';

const Dashboard = lazyWithRetry(() => import('./index'));

export const paths = {
    DASHBOARD_INDEX: '/',
};

export const routes: RouteWithLayout[] = [
    {
        exact: true,
        path: paths.DASHBOARD_INDEX,
        component: Dashboard,
        layout: AuthenticatedLayout,
    },
];
