import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { HighlightState } from 'typings/state';

export const initialState: HighlightState = resourceInitialState();

const slice = createResourceSlice({
    name: 'highlight',
    initialState,
});

export const highlight = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
