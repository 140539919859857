import { paths, routes } from 'pages/cancelReason/routes';
import api from 'resources/cancelReason/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/cancelReason/selectors';
import { actions } from 'store/cancelReason/slice';
import { CancelReasonApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<CancelReasonApiItem> = {
    name: 'cancelReason',
    translationKey: 'cancelReason',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/cancelReason')),
};

export default resource;
