import { paths, routes } from 'pages/requestContact/routes';
import api from 'resources/requestContact/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/requestContact/selectors';
import { actions } from 'store/requestContact/slice';
import { RequestContactApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<RequestContactApiItem, typeof api> = {
    name: 'requestContact',
    translationKey: 'requestContact',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/requestContact')),
};

export default resource;
