import lazyWithRetry from 'services/lazyWithRetry';
import { Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const RoomTypeListPage = lazyWithRetry(() => import('./List'));
const RoomTypeAddPage = lazyWithRetry(() => import('./Add'));
const RoomTypeEditPage = lazyWithRetry(() => import('./Edit'));

export const paths = {
    LIST: '/room-type',
    ADD: '/room-type/add',
    EDIT: '/room-type/:id/edit',
};

export const routes: RouteCollection = {
    list: {
        exact: true,
        path: paths.LIST,
        component: RoomTypeListPage,
        roles: [Roles.ADMIN],
    },
    add: {
        exact: true,
        path: paths.ADD,
        component: RoomTypeAddPage,
        roles: [Roles.ADMIN],
    },
    edit: {
        exact: true,
        path: paths.EDIT,
        component: RoomTypeEditPage,
        roles: [Roles.ADMIN],
    },
};
