import { PayloadAction } from '@reduxjs/toolkit';
import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { EnquiryApiItem } from 'typings/api';
import { EnquiryState as EnumEnquiryState } from 'typings/enums';
import { EnquiryState } from 'typings/state';

const hiddenStates = [EnumEnquiryState.STATE_CANCELED, EnumEnquiryState.STATE_VALIDATED];

const listInitialState = resourceInitialState();
listInitialState.list.filters = [
    [],
    [],
    [],
    [],
    [],
    [],
    [],
    Object.values(EnumEnquiryState).filter((state) => !hiddenStates.includes(state)),
];

export const initialState: EnquiryState = {
    ...listInitialState,
    selection: {
        id: undefined,
        rentals: [],
    },
};

const slice = createResourceSlice({
    name: 'enquiry',
    initialState,
    reducers: {
        resetSelection: (state): void => {
            state.selection.id = undefined;
            state.selection.rentals = [];
        },
        startSelection: (state, action: PayloadAction<EnquiryApiItem>): void => {
            state.selection.id = action.payload['@id'];
            state.selection.rentals = action.payload.selections || [];
        },
        addToSelection: (state, action: PayloadAction<string>): void => {
            if (state.selection.id && !state.selection.rentals.includes(action.payload)) {
                state.selection.rentals.push(action.payload);
            }
        },
        removeFromSelection: (state, action: PayloadAction<string>): void => {
            state.selection.rentals = [...state.selection.rentals.filter((id: string) => id !== action.payload)];
        },
    },
});

export const enquiry = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
