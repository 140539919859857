import { paths, routes } from 'pages/creditNote/routes';
import api from 'resources/creditNote/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/creditNote/selectors';
import { actions } from 'store/creditNote/slice';
import { CreditNoteApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<CreditNoteApiItem, typeof api> = {
    name: 'creditNote',
    translationKey: 'creditNote',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/creditNote')),
};

export default resource;
