import { AxiosPromise } from 'axios';
import { createResourceApi, DEFAULT_FETCHED_ITEMS_PER_AGE } from 'resources/ResourceApi';
import { normalizeUri, requestApi } from 'services/axios';
import { CollectionJsonLD, OwnerApiItem, RentalApiItem } from 'typings/api';

const rentals = (
    owner: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<RentalApiItem>> => {
    const queryParameters = {
        page,
        itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('owners', owner)}/rentals`,
        params: queryParameters,
    });
};

export default { ...createResourceApi<OwnerApiItem>('owners'), rentals };
