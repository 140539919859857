import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { PartnerState } from 'typings/state';

export const initialState: PartnerState = resourceInitialState();

const slice = createResourceSlice({
    name: 'partner',
    initialState,
});

export const partner = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
