import { paths, routes } from 'pages/bed/routes';
import api from 'resources/bed/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/bed/selectors';
import { actions } from 'store/bed/slice';
import { BedApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<BedApiItem> = {
    name: 'bed',
    translationKey: 'bed',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/bed')),
};

export default resource;
