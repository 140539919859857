import { paths, routes } from 'pages/rental/routes';
import api from 'resources/rental/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/rental/selectors';
import { actions } from 'store/rental/slice';
import { RentalApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<RentalApiItem, typeof api> = {
    name: 'rental',
    translationKey: 'rental',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/rental')),
};

export default resource;
