import {
    Badge,
    Box,
    Button,
    Drawer,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    Toolbar,
    Typography,
} from '@material-ui/core';
import { Delete as DeleteIcon, PlaylistAddCheck as SelectionIcon } from '@material-ui/icons';
import RentalLabel from 'components/Entities/Rental/RentalLabel';
import RentalIcon from 'components/Icons/RentalIcon';
import { paths } from 'pages/routes';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import resource from 'resources/enquiry';
import enquiryAPI from 'resources/enquiry/api';
import { iriToId } from 'services/axios';
import { flash } from 'services/bus';
import { AppDispatch } from 'store';
import selectors from 'store/enquiry/selectors';
import { actions } from 'store/enquiry/slice';
import { RentalListItem, SelectionButton, SelectionPaper } from './styled';

export const FloatingSelection: React.FC = () => {
    const { t } = useTranslation('enquiry');
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const history = useHistory();
    const selection = useSelector(selectors.selectSelection);
    const client = useQueryClient();

    const saveSelection = useMutation(
        () => {
            if (selection.id) {
                return enquiryAPI.updateSelection(selection.id, selection.rentals).then((response) => response.data);
            }

            throw new Error('Selection id is not set');
        },
        {
            mutationKey: enquiryAPI.apiKey,
            onSuccess: (enquiry) => {
                client.setQueryData([resource.api.apiKey, { id: enquiry['@id'] }], enquiry);
                dispatch(actions.resetSelection());
                flash({ message: 'enquiry:selection_drawer.saved', type: 'success' });
                paths.enquiry?.SHOW && history.push(paths.enquiry.SHOW.replace(':id', enquiry.id));
            },
        },
    );

    const toggle = (): void => setOpen(!open);
    const removeRental = (id: string): any => dispatch(actions.removeFromSelection(id));

    if (!selection.id) {
        return null;
    }

    return (
        <>
            <SelectionButton variant="contained" onClick={toggle}>
                <Badge badgeContent={selection.rentals.length} color="error">
                    <SelectionIcon />
                </Badge>
            </SelectionButton>
            <Drawer anchor="right" open={open} onClose={toggle} PaperProps={{ component: SelectionPaper }}>
                <Toolbar>
                    <Typography variant="h6">{t('enquiry:selection_drawer.title')}</Typography>
                </Toolbar>
                <Grid container spacing={2} direction="column" wrap="nowrap" style={{ height: '100%' }}>
                    <Box flexGrow={1}>
                        <List>
                            {selection.rentals.map((rentalId) => (
                                <ListItem button key={rentalId}>
                                    <ListItemIcon>
                                        <RentalIcon />
                                    </ListItemIcon>
                                    <RentalListItem
                                        primary={<RentalLabel id={rentalId} />}
                                        onClick={(): any =>
                                            paths.rental.SHOW &&
                                            history.push(paths.rental.SHOW.replace(':id', iriToId('rentals', rentalId)))
                                        }
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            onClick={(): void => removeRental(rentalId)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                    <Box>
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            onClick={(): any => saveSelection.mutate()}
                        >
                            {t('form:buttons.save')}
                        </Button>
                    </Box>
                </Grid>
            </Drawer>
        </>
    );
};
