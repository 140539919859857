import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { TeamState } from 'typings/state';

export const initialState: TeamState = resourceInitialState();

const slice = createResourceSlice({
    name: 'team',
    initialState,
});

export const team = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
