import { invalidateAllPages, invalidateId, invalidateLinkedToId, QueryType } from 'hooks/useCrudQueries';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { QueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { invalidateAdvancedSearchCache, invalidateAdvancedSearchCacheWithRental } from 'services/cache';
import { resourceMapping, sharedResources } from 'store';
import userSelectors from 'store/user/selectors';
import { OrNull } from 'typings/shared';

export const useMercure = (queryClient: QueryClient): OrNull<EventSource> => {
    const [cookie, setCookies] = useCookies(['mercureAuthorization']);
    let eventSource: OrNull<EventSource> = null;
    const profile = useSelector(userSelectors.selectConnectedUser);

    useEffect(() => {
        if (undefined === cookie.mercureAuthorization) {
            setCookies('mercureAuthorization', process.env.REACT_APP_MERCURE_JWT, {});
        }
    }, []);

    useEffect(() => {
        if (null !== eventSource) {
            eventSource.onmessage = null;
            eventSource.close();
        }

        const url = new URL(`${process.env.REACT_APP_MERCURE_ENDPOINT}`);
        url.searchParams.append('topic', `${process.env.REACT_APP_API_ENDPOINT}${profile?.team}`);
        sharedResources.forEach((publicResource) =>
            url.searchParams.append('topic', `${process.env.REACT_APP_API_ENDPOINT}/${publicResource}/{id}`),
        );

        eventSource = new EventSource(url.toString(), { withCredentials: true });
        eventSource.onmessage = (e): void => {
            const data = JSON.parse(e.data);
            const regex = /\/(.+)\/(.+)/;
            const groups = data['@id'].match(regex);
            if (groups.length > 1) {
                const item = groups[1];
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const resource = resourceMapping[item];
                if (undefined !== resource) {
                    // noinspection JSIgnoredPromiseFromCall
                    queryClient.invalidateQueries({
                        predicate: (query) => invalidateId(item, data['@id'], query as QueryType),
                    });

                    // noinspection JSIgnoredPromiseFromCall
                    queryClient.invalidateQueries({
                        predicate: (query) => invalidateLinkedToId(data['@id'], query as QueryType),
                    });
                    // noinspection JSIgnoredPromiseFromCall
                    queryClient.invalidateQueries({
                        predicate: (query) => invalidateAllPages(item, query as QueryType),
                    });
                    if (undefined !== data['@context']) {
                        queryClient.setQueryData([item, { id: data['@id'] }], data);
                    }
                }
                switch (resource) {
                    case 'unavailability':
                        queryClient && invalidateAdvancedSearchCache(queryClient);
                        break;
                    case 'rental':
                        queryClient && invalidateAdvancedSearchCacheWithRental(queryClient, data['@id']);
                        break;
                    default:
                        break;
                }
            }
        };

        window.onunload = (): void => {
            if (null !== eventSource) {
                eventSource.close();
            }
        };

        eventSource.onerror = (e): void => {
            console.warn(e);
        };

        return (): void => {
            if (null !== eventSource) {
                eventSource.close();
            }
        };
    }, [cookie, profile]);

    return eventSource;
};
