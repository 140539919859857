/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { stateByType } from 'store/booking/slice';

const migrations = {
    2: (state: any): any => ({
        ...state,
        booking: {
            ...state.booking,
            listOption: {
                ...state.booking.listOption,
                filters: [[], [], [], [], [], [], [], [], [], [], [], stateByType.option],
            },
            listConfirmed: {
                ...state.booking.listConfirmed,
                filters: [[], [], [], [], [], [], [], [], [], [], [], stateByType.confirmed],
            },
            listDue: {
                ...state.booking.listDue,
                filters: [[], [], [], [], [], [], [], [], [], [], [], stateByType.due],
            },
            listArchived: {
                ...state.booking.listArchived,
                filters: [[], [], [], [], [], [], [], [], [], [], [], stateByType.archived],
            },
        },
    }),
    3: (state: any): any => ({
        ...state,
        booking: {
            ...state.booking,
            listOption: {
                ...state.booking.listOption,
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.option],
            },
            listConfirmed: {
                ...state.booking.listConfirmed,
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.confirmed],
            },
            listDue: {
                ...state.booking.listDue,
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.due],
            },
            listArchived: {
                ...state.booking.listArchived,
                filters: [[], [], [], [], [], [], [], [], [], [], [], [], stateByType.archived],
            },
        },
    }),
    4: (state: any): any => {
        const { list: listAccounting, ...accountingState } = state.accounting;
        return {
            ...state,
            accounting: {
                ...accountingState,
                listBank: {
                    ...listAccounting,
                    columns: {
                        rentalTitle: false,
                        bookingNumber: true,
                        bookingStartAt: false,
                        bookingEndAt: true,
                        bookingNetAmount: true,
                        bookingPaidAmount: true,
                        regularisationAmount: true,
                        ownerPaidAmount: true,
                        paymentAmount: true,
                        paymentDate: true,
                        paymentMode: true,
                    },
                },
                listCommission: {
                    ...listAccounting,
                    columns: {
                        rentalTitle: false,
                        bookingNumber: true,
                        bookingStartAt: false,
                        bookingEndAt: true,
                        commissionAmount: true,
                        commissionRate: true,
                        partnerCommissionAmount: true,
                        partnerCommissionRate: false,
                        agencyCommissionAmount: true,
                    },
                },
                listSales: {
                    ...listAccounting,
                    columns: {
                        rentalTitle: false,
                        bookingNumber: true,
                        bookingStartAt: false,
                        bookingEndAt: true,
                        bookingNetAmount: true,
                        bookingPaidAmount: true,
                        serviceFeeAmount: true,
                        serviceFeeRate: false,
                        touristTaxAmount: true,
                        commissionAmount: true,
                        commissionRate: false,
                        partnerCommissionAmount: true,
                        partnerCommissionRate: false,
                        agencyCommissionAmount: true,
                    },
                },
            },
            bed: {
                ...state.bed,
                list: {
                    ...state.bed.list,
                    columns: {
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                    },
                },
            },
            booking: {
                ...state.booking,
                listOption: {
                    ...state.booking.listOption,
                    columns: {
                        number: false,
                        customerFullName: false,
                        customerBilled: false,
                        checkIn: true,
                        checkOut: true,
                        rental: true,
                        customer: true,
                        partner: true,
                        createdAt: true,
                        paidAmount: false,
                        totalAmount: true,
                        state: true,
                    },
                },
                listConfirmed: {
                    ...state.booking.listConfirmed,
                    columns: {
                        number: false,
                        customerFullName: false,
                        customerBilled: false,
                        checkIn: true,
                        checkOut: true,
                        rental: true,
                        customer: true,
                        partner: true,
                        createdAt: true,
                        paidAmount: true,
                        totalAmount: true,
                        state: true,
                    },
                },
                listDue: {
                    ...state.booking.listDue,
                    columns: {
                        number: false,
                        customerFullName: false,
                        customerBilled: false,
                        checkIn: false,
                        checkOut: true,
                        rental: true,
                        customer: true,
                        partner: true,
                        createdAt: false,
                        paidAmount: true,
                        totalAmount: true,
                        state: true,
                    },
                },
                listArchived: {
                    ...state.booking.listArchived,
                    columns: {
                        number: false,
                        customerFullName: false,
                        customerBilled: false,
                        checkIn: false,
                        checkOut: true,
                        rental: true,
                        customer: true,
                        partner: true,
                        createdAt: false,
                        paidAmount: false,
                        totalAmount: true,
                        state: true,
                    },
                },
            },
            cancelReason: {
                ...state.cancelReason,
                list: {
                    ...state.cancelReason.list,
                    columns: {
                        title: true,
                    },
                },
            },
            competitor: {
                ...state.competitor,
                list: {
                    ...state.competitor.list,
                    columns: {
                        title: true,
                    },
                },
            },
            customer: {
                ...state.customer,
                list: {
                    ...state.customer.list,
                    columns: {
                        lastName: true,
                        company: false,
                        email: true,
                        phone: true,
                        assignedToPartner: true,
                        assignedTo: true,
                    },
                },
            },
            enquiry: {
                ...state.enquiry,
                list: {
                    ...state.enquiry.list,
                    columns: {
                        receivedAt: true,
                        name: true,
                        assignedTo: true,
                        period: true,
                        origin: true,
                        currentPlace: true,
                    },
                },
            },
            furnishing: {
                ...state.furnishing,
                list: {
                    ...state.furnishing.list,
                    columns: {
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                        roomTypes: true,
                    },
                },
            },
            highlight: {
                ...state.highlight,
                list: {
                    ...state.highlight.list,
                    columns: {
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                    },
                },
            },
            location: {
                ...state.location,
                list: {
                    ...state.location.list,
                    columns: {
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                    },
                },
            },
            manager: {
                ...state.manager,
                list: {
                    ...state.manager.list,
                    columns: {
                        lastName: true,
                        company: false,
                        email: true,
                        phone: true,
                    },
                },
            },
            origin: {
                ...state.origin,
                list: {
                    ...state.origin.list,
                    columns: {
                        title: true,
                    },
                },
            },
            owner: {
                ...state.owner,
                list: {
                    ...state.owner.list,
                    columns: {
                        lastName: true,
                        company: true,
                        email: true,
                        phone: true,
                    },
                },
            },
            partner: {
                ...state.partner,
                list: {
                    ...state.partner.list,
                    columns: {
                        lastName: true,
                        company: true,
                        email: true,
                        phone: true,
                    },
                },
            },
            paymentMode: {
                ...state.paymentMode,
                list: {
                    ...state.paymentMode.list,
                    columns: {
                        code: true,
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                        chargesRate: true,
                    },
                },
            },
            poi: {
                ...state.poi,
                list: {
                    ...state.poi.list,
                    columns: {
                        code: true,
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                        unit: true,
                    },
                },
            },
            rental: {
                ...state.rental,
                list: {
                    ...state.rental.list,
                    columns: {
                        title: true,
                        capacity: true,
                        bedroomCount: true,
                        bathroomCount: false,
                        published: false,
                        planningModifiedAt: true,
                        planningModifiedBy: true,
                    },
                },
            },
            requestContact: {
                ...state.requestContact,
                list: {
                    ...state.requestContact.list,
                    columns: {
                        createdAt: true,
                        subject: true,
                        contact: true,
                        email: true,
                        phoneNumber: true,
                    },
                },
            },
            roomType: {
                ...state.roomType,
                list: {
                    ...state.roomType.list,
                    columns: {
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                        position: true,
                    },
                },
            },
            season: {
                ...state.season,
                list: {
                    ...state.season.list,
                    columns: {
                        periodStart: true,
                        periodEnd: true,
                        period: true,
                        'translations.fr_FR.title': true, // eslint-disable-line @typescript-eslint/naming-convention
                        minBookingDays: true,
                        nbDaysLimitPaidBalance: true,
                    },
                },
            },
            team: {
                ...state.team,
                list: {
                    ...state.team.list,
                    columns: {
                        name: true,
                        rentalAgency: true,
                    },
                },
            },
            user: {
                ...state.user,
                list: {
                    ...state.user.list,
                    columns: {
                        lastName: true,
                        email: true,
                        roles: true,
                    },
                },
            },
        };
    },
};

export default migrations;
