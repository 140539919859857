import lazyWithRetry from 'services/lazyWithRetry';
import { Roles } from 'typings/enums';
import { RouteCollection } from 'typings/shared';

const RentalListPage = lazyWithRetry(() => import('./List'));
const RentalAddPage = lazyWithRetry(() => import('./Add'));
const RentalEditPage = lazyWithRetry(() => import('./Edit'));
const RentalShowPage = lazyWithRetry(() => import('./Show'));

export const paths = {
    LIST: '/rental/list',
    SHOW: '/rental/:id/show',
    ADD: '/rental/add',
    EDIT: '/rental/:id/edit',
    ADD_STEP_2: '/rental/add/step2',
    ADD_STEP_3: '/rental/add/step3',
};

export const routes: RouteCollection = {
    list: {
        exact: false,
        path: paths.LIST,
        component: RentalListPage,
        roles: [Roles.BASE_USER],
    },
    add: {
        exact: false,
        path: paths.ADD,
        component: RentalAddPage,
        roles: [Roles.TRADER, Roles.WEBMASTER],
    },
    edit: {
        exact: false,
        path: paths.EDIT,
        component: RentalEditPage,
        roles: [Roles.TRADER, Roles.WEBMASTER],
    },
    show: {
        exact: true,
        path: paths.SHOW,
        component: RentalShowPage,
        roles: [Roles.BASE_USER],
    },
};
