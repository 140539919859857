import { AxiosPromise } from 'axios';
import { createResourceApi, DEFAULT_FETCHED_ITEMS_PER_AGE } from 'resources/ResourceApi';
import { normalizeUri, requestApi } from 'services/axios';
import { CollectionJsonLD, EnquiryApiItem, EnquiryHistoryApiItem } from 'typings/api';
import { EnquiryFormValues } from 'typings/forms';

const fetchHistoryPage = (
    id: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
    filters?: Record<string, any>,
): AxiosPromise<CollectionJsonLD<EnquiryHistoryApiItem>> =>
    requestApi<CollectionJsonLD<EnquiryHistoryApiItem>>({
        url: `${normalizeUri('enquiries', id)}/enquiry_histories`,
        params: {
            ...filters,
            page,
            perPage: itemsPerPage,
        },
    });

const updateSelection = (id: string, data: Array<string>): AxiosPromise<EnquiryApiItem> =>
    requestApi<EnquiryApiItem>({
        url: `${normalizeUri('enquiries', id)}/selection`,
        method: 'PATCH',
        data: { selections: data },
        headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/merge-patch+json',
        },
    });

const searchEnquiriesById = (ids: Array<string>): AxiosPromise<CollectionJsonLD<EnquiryApiItem>> =>
    requestApi<CollectionJsonLD<EnquiryApiItem>>({
        url: '/enquiries',
        params: {
            id: ids,
        },
    });

const mergeEnquiries = (
    enquiry: string,
    data: EnquiryFormValues,
    enquiriesToMerge: Array<string>,
): AxiosPromise<void> =>
    requestApi<void>({
        url: `${normalizeUri('enquiries', enquiry)}/merge-enquiry`,
        method: 'PUT',
        data,
        params: { enquiries: enquiriesToMerge },
    });

export default {
    ...createResourceApi<EnquiryApiItem>('enquiries'),
    updateSelection,
    fetchHistoryPage,
    searchEnquiriesById,
    mergeEnquiries,
};
