import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Bus from 'services/bus';
import { getSwitchUser, getToken } from 'services/security';
import { DownloadEvent } from 'typings/shared';

export const FileDownloader: React.FC = () => {
    const { t } = useTranslation('layout');
    const [downloading, setDownloading] = useState(false);

    useEffect(() => {
        let unmounted = false;
        Bus.addListener('download', ({ promise, entrypoint, contentType }: DownloadEvent<File>): void => {
            if (!unmounted) {
                setDownloading(true);
            }
            if (promise) {
                promise()
                    .then(({ data }) => {
                        const blob = new Blob([data], { type: contentType });
                        const link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.target = '_blank';
                        link.click();
                        if (!unmounted) {
                            setDownloading(false);
                            window.URL.revokeObjectURL(link.href);
                        }
                    })
                    .catch(() => {
                        if (!unmounted) {
                            setDownloading(false);
                        }
                    });
            } else if (entrypoint) {
                const url = `${process.env.REACT_APP_API_ENDPOINT}${entrypoint}${
                    entrypoint.indexOf('?') > -1 ? '&' : '?'
                }_bearer=${getToken()}&WITH-ARCHIVED=1${getSwitchUser() ? `&_switch_user=${getSwitchUser()}` : ''}`;

                if (!unmounted) {
                    setDownloading(false);
                }
                window.open(url, '_blank', 'noopener noreferrer');
            }
        });

        return (): any => (unmounted = true);
    }, []);

    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={downloading}
                message={t('layout:messages.downloading')}
            >
                <Alert severity="info">{t('layout:messages.downloading')}</Alert>
            </Snackbar>
        </>
    );
};
