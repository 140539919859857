import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { FurnishingState } from 'typings/state';

export const initialState: FurnishingState = resourceInitialState();

const slice = createResourceSlice({
    name: 'furnishing',
    initialState,
});

export const furnishing = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
