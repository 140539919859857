import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { LocaleState } from 'typings/state';

export const initialState: LocaleState = resourceInitialState();

const slice = createResourceSlice({
    name: 'locale',
    initialState,
});

export const locale = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
