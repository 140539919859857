import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { OriginState } from 'typings/state';

export const initialState: OriginState = resourceInitialState();

const slice = createResourceSlice({
    name: 'origin',
    initialState,
});

export const origin = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
