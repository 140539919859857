import { paths, routes } from 'pages/partner/routes';
import api from 'resources/partner/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/partner/selectors';
import { actions } from 'store/partner/slice';
import { PartnerApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<PartnerApiItem> = {
    name: 'partner',
    translationKey: 'partner',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/partner')),
};

export default resource;
