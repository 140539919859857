import { paths, routes } from 'pages/location/routes';
import api from 'resources/location/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/location/selectors';
import { actions } from 'store/location/slice';
import { LocationApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<LocationApiItem> = {
    name: 'location',
    translationKey: 'location',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/location')),
};

export default resource;
