import { PayloadAction } from '@reduxjs/toolkit';
import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { RentalType } from 'typings/enums';
import {
    RentalAddState,
    RentalAddStep1,
    RentalAddStep2,
    RentalAddStep3,
    RentalAdvancedSearch,
    RentalState,
} from 'typings/state';

export const MAX_STEP_FORM_RENTAL_ADD = 2;

const initialStep1: RentalAddStep1 = {
    title: '',
    rentalType: RentalType.RENTAL_TYPE_VILLA,
    location: '',
    owner: '',
    manager: '',
};

const initialStep2: RentalAddStep2 = {
    rooms: {},
    bathroomCount: 1,
    bedroomCount: 3,
};

const initialStep3: RentalAddStep3 = {
    prices: [],
};

const initialRentalAddState: RentalAddState = {
    currentStep: 0,
    lastStep: false,
    step1: initialStep1,
    step2: initialStep2,
    step3: initialStep3,
};

export const initialAdvancedSearchState: RentalAdvancedSearch = {
    bedrooms: 1,
    capacity: 1,
    fixedPeriod: true,
    minBookingDays: 7,
    location: [],
    price: {
        min: 0,
        max: 1000000,
    },
    periodStart: null,
    periodEnd: null,
    page: 1,
};

export const initialState: RentalState = {
    ...resourceInitialState(),
    advancedSearch: initialAdvancedSearchState,
    add: initialRentalAddState,
    listTab: 0,
};

const slice = createResourceSlice({
    name: 'rental',
    initialState,
    reducers: {
        setAdvancedSearchFilters: (state, action: PayloadAction<RentalAdvancedSearch>): void => {
            state.advancedSearch = action.payload;
        },
        setStep: (state, action: PayloadAction<number>): void => {
            state.add.currentStep = action.payload;
        },
        nextStep: (state): void => {
            let next = ++state.add.currentStep;
            next > MAX_STEP_FORM_RENTAL_ADD ? (next = MAX_STEP_FORM_RENTAL_ADD) : null;
            state.add.currentStep = next;
            state.add.lastStep = MAX_STEP_FORM_RENTAL_ADD === state.add.currentStep;
        },
        prevStep: (state): void => {
            let prev = --state.add.currentStep;
            prev < 0 ? (prev = 0) : null;
            state.add.currentStep = prev;
            state.add.lastStep = MAX_STEP_FORM_RENTAL_ADD === state.add.currentStep;
        },
        resetAddState: (state, _action: PayloadAction<boolean>): void => {
            state.add = initialRentalAddState;
        },
        setDataStep1: (state, action: PayloadAction<RentalAddStep1>): void => {
            const { title, rentalType, location, owner, manager } = action.payload;
            state.add.step1.title = title;
            state.add.step1.rentalType = rentalType;
            state.add.step1.location = location;
            state.add.step1.owner = owner;
            state.add.step1.manager = manager;
        },
        setDataStep2: (state, action: PayloadAction<RentalAddStep2>): void => {
            const { rooms, bathroomCount, bedroomCount } = action.payload;
            state.add.step2.rooms = rooms;
            state.add.step2.bathroomCount = bathroomCount;
            state.add.step2.bedroomCount = bedroomCount;
        },
        changeTab: (state, action: PayloadAction<number>): void => {
            state.listTab = action.payload;
        },
    },
});

export const rental = slice.reducer;
export const actions = slice.actions;
