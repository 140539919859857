import { AxiosPromise } from 'axios';
import { iriToId, normalizeUri, requestApi } from 'services/axios';
import { createResourceApi } from 'resources/ResourceApi';
import { OriginApiItem } from 'typings/api';

const merge = (source: string, merged: string): AxiosPromise<void> =>
    requestApi<void>({
        url: `${normalizeUri('origins', source)}/merge-with/${iriToId('origins', merged)}`,
        method: 'GET',
    });

export default {
    ...createResourceApi<OriginApiItem>('origins'),
    merge,
};
