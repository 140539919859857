import { LinearProgress } from '@material-ui/core';
import { useEntityFetch } from 'hooks/useCrudQueries';
import resource from 'resources/rental';
import React from 'react';

type Props = {
    id?: string;
};

export const RentalLabel: React.FC<Props> = ({ id }) => {
    const { isLoading, data } = useEntityFetch(resource, id);
    if (isLoading) {
        return <LinearProgress />;
    }

    if (null === data || undefined === id) {
        return null;
    }

    return <>{data?.title}</>;
};
