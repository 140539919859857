import { paths, routes } from 'pages/furnishing/routes';
import api from 'resources/furnishing/api';
import lazyWithRetry from 'services/lazyWithRetry';
import selectors from 'store/furnishing/selectors';
import { actions } from 'store/furnishing/slice';
import { FurnishingApiItem } from 'typings/api';
import { Resource } from 'typings/shared';

const resource: Resource<FurnishingApiItem> = {
    name: 'furnishing',
    translationKey: 'furnishing',
    api,
    selectors,
    paths,
    routes,
    actions,
    component: lazyWithRetry(() => import('pages/furnishing')),
};

export default resource;
