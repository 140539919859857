import AuthenticatedLayout from 'components/Security/AuthenticatedLayout';
import lazyWithRetry from 'services/lazyWithRetry';
import { Roles } from 'typings/enums';
import { RouteWithLayout } from 'typings/shared';

const AccountingBankPage = lazyWithRetry(() => import('./Bank'));
const AccountingSalesPage = lazyWithRetry(() => import('./Sales'));
const AccountingCommissionPage = lazyWithRetry(() => import('./Commission'));

export const paths = {
    ACCOUNTING_BANK: '/accounting/bank',
    ACCOUNTING_SALES: '/accounting/sales',
    ACCOUNTING_COMMISSION: '/accounting/commission',
};

export const accountingRoutes: RouteWithLayout[] = [
    {
        exact: true,
        path: paths.ACCOUNTING_BANK,
        component: AccountingBankPage,
        layout: AuthenticatedLayout,
        roles: [Roles.ACCOUNTING],
    },
    {
        exact: true,
        path: paths.ACCOUNTING_SALES,
        component: AccountingSalesPage,
        layout: AuthenticatedLayout,
        roles: [Roles.ACCOUNTING],
    },
    {
        exact: true,
        path: paths.ACCOUNTING_COMMISSION,
        component: AccountingCommissionPage,
        layout: AuthenticatedLayout,
        roles: [Roles.ACCOUNTING],
    },
];
