import { AxiosPromise, AxiosRequestConfig } from 'axios';
import { createResourceApi } from 'resources/ResourceApi';
import { requestApi } from 'services/axios';
import { CustomerApiItem } from 'typings/api';

const fetchByEmail = (email: string, options?: AxiosRequestConfig): AxiosPromise<CustomerApiItem> =>
    requestApi<CustomerApiItem>({
        url: `customers/find-by-email/${email}`,
        ...options,
    });

export default {
    ...createResourceApi<CustomerApiItem>('customers'),
    fetchByEmail,
};
