import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { BedState } from 'typings/state';

export const initialState: BedState = resourceInitialState();

const slice = createResourceSlice({
    name: 'bed',
    initialState,
});

export const bed = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
