import { AxiosPromise } from 'axios';
import { differenceInCalendarDays, format, parseISO } from 'date-fns';
import { createResourceApi, DEFAULT_FETCHED_ITEMS_PER_AGE } from 'resources/ResourceApi';
import { normalizeUri, requestApi } from 'services/axios';
import {
    BedroomRangeApi,
    CollectionJsonLD,
    HighlightApiItem,
    MandateApiItem,
    OccupancyStatsApi,
    PointOfInterestRentalApiItem,
    PriceApiItem,
    PriceRangeApi,
    RentalApiItem,
    RentalPictureApiItem,
    RentalSeasonApiItem,
    RoomApiItem,
    SpecialOfferApiItem,
    UnavailabilityApiItem,
} from 'typings/api';
import { OrNull } from 'typings/shared';
import { RentalAdvancedSearch } from 'typings/state';

const buildParameters = (searchData: RentalAdvancedSearch, page: number, itemsPerPage: number): any => ({
    price: searchData.price,
    searchPeriod: {
        periodStart: format(parseISO(searchData.periodStart || ''), 'yyyy-MM-dd'),
        periodEnd: format(parseISO(searchData.periodEnd || ''), 'yyyy-MM-dd'),
        minBookingDays:
            searchData.fixedPeriod ?? true
                ? differenceInCalendarDays(parseISO(searchData.periodEnd || ''), parseISO(searchData.periodStart || ''))
                : searchData.minBookingDays,
    },
    bedroomCount: {
        gte: searchData.bedrooms,
    },
    capacity: {
        gte: searchData.capacity,
    },
    location: searchData.location,
    page,
    perPage: itemsPerPage,
});

const priceRange = (): AxiosPromise<PriceRangeApi> =>
    requestApi({
        url: '/rentals/aggregate/price_range',
    });

const bedroomRange = (): AxiosPromise<BedroomRangeApi> =>
    requestApi({
        url: '/rentals/aggregate/bedroom_range',
    });

const rentalPriceRange = (
    rental: string,
    searchData: Pick<RentalAdvancedSearch, 'periodStart' | 'periodEnd' | 'bedrooms'>,
): AxiosPromise<PriceRangeApi> =>
    requestApi({
        url: `${normalizeUri('rentals', rental)}/price_range`,
        params: {
            periodStart: format(parseISO(searchData.periodStart || ''), 'yyyy-MM-dd'),
            periodEnd: format(parseISO(searchData.periodEnd || ''), 'yyyy-MM-dd'),
            bedrooms: searchData.bedrooms,
        },
    });

const advancedSearch = (
    searchData: RentalAdvancedSearch,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<RentalApiItem>> => {
    const queryParameters = buildParameters(searchData, page, itemsPerPage);
    return requestApi({
        url: `/rentals`,
        params: queryParameters,
    });
};

const searchRentals = async (
    searchData: RentalAdvancedSearch,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): Promise<CollectionJsonLD<RentalApiItem>> => {
    const queryParameters = buildParameters(searchData, page, itemsPerPage);
    const response = await requestApi({
        url: `/rentals`,
        params: queryParameters,
    });
    return response.data as CollectionJsonLD<RentalApiItem>;
};

const unavailabilities = (
    rental: string,
    filterData: { periodStart?: OrNull<string>; periodEnd?: OrNull<string> },
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<UnavailabilityApiItem>> => {
    const queryParameters = {
        periodEnd: {
            after: filterData.periodStart,
        },
        periodStart: {
            before: filterData.periodEnd,
        },
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('rentals', rental)}/unavailabilities`,
        params: queryParameters,
    });
};

const prices = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<PriceApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('rentals', rental)}/prices`,
        params: queryParameters,
    });
};

const pictures = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<RentalPictureApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('rentals', rental)}/pictures`,
        params: queryParameters,
    });
};

const rooms = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<RoomApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('rentals', rental)}/rooms`,
        params: queryParameters,
    });
};

const specialOffers = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<SpecialOfferApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('rentals', rental)}/special_offers`,
        params: queryParameters,
    });
};

const rentalSeasons = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<RentalSeasonApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('rentals', rental)}/rental_seasons`,
        params: queryParameters,
    });
};

const highlights = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<HighlightApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('rentals', rental)}/highlights`,
        params: queryParameters,
    });
};

const pois = (
    rental: string,
    page: number,
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<PointOfInterestRentalApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
    };
    return requestApi({
        url: `${normalizeUri('rentals', rental)}/point_of_interests`,
        params: queryParameters,
    });
};

const rentalPicturesZip = (rentalSlug: string): AxiosPromise<File> =>
    requestApi({
        url: `/rentals/pictures/${rentalSlug}.zip`,
        responseType: 'blob',
    });

const isAvailable = (id: string, periodStart: string, periodEnd: string): AxiosPromise<{ available: boolean }> =>
    requestApi({
        url: `${normalizeUri('rentals', id)}/is_available/${periodStart}/${periodEnd}`,
    });

const mandates = (
    rental: string,
    page: number,
    filterData: { expiresAfter?: OrNull<string> },
    itemsPerPage = DEFAULT_FETCHED_ITEMS_PER_AGE,
): AxiosPromise<CollectionJsonLD<MandateApiItem>> => {
    const queryParameters = {
        page,
        perPage: itemsPerPage,
        expiresAt: {},
    };
    if (filterData?.expiresAfter) {
        queryParameters.expiresAt = {
            after: filterData?.expiresAfter,
        };
    }
    return requestApi({
        url: `${normalizeUri('rentals', rental)}/mandates`,
        params: queryParameters,
    });
};

const rentalMandateExport = (mandate: string): AxiosPromise<File> =>
    requestApi({
        url: `${normalizeUri('mandates', mandate)}/export`,
        responseType: 'blob',
    });

const statsOccupancy = (
    rental: string,
    searchData: Pick<RentalAdvancedSearch, 'periodStart' | 'periodEnd'>,
): AxiosPromise<OccupancyStatsApi> => {
    const periodStart = format(parseISO(searchData.periodStart || ''), 'yyyy-MM-dd');
    const periodEnd = format(parseISO(searchData.periodEnd || ''), 'yyyy-MM-dd');
    return requestApi({
        url: `${normalizeUri('rentals', rental)}/stats/occupancy/${periodStart}/${periodEnd}`,
    });
};

export default {
    ...createResourceApi<RentalApiItem>('rentals'),
    priceRange,
    rentalPriceRange,
    bedroomRange,
    advancedSearch,
    unavailabilities,
    highlights,
    pois,
    prices,
    pictures,
    rooms,
    specialOffers,
    rentalSeasons,
    rentalPicturesZip,
    searchRentals,
    isAvailable,
    mandates,
    rentalMandateExport,
    statsOccupancy,
};
