import { createResourceSlice, resourceInitialState } from 'store/ResourceSlice';
import { CompetitorState } from 'typings/state';

export const initialState: CompetitorState = resourceInitialState();

const slice = createResourceSlice({
    name: 'competitor',
    initialState,
});

export const competitor = slice.reducer;
export const actions: typeof slice.actions = slice.actions;
