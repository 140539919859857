import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'store/index';
import createResourceListSelectors from 'store/ResourceListSelectors';
import {
    RentalAddState,
    RentalAddStep1,
    RentalAddStep2,
    RentalAddStep3,
    RentalAdvancedSearch,
    RentalState,
} from 'typings/state';

const getResourceState = (state: RootState): RentalState => state.rental;

const selectFormAddStep = createSelector<RootState, RentalState, number>(
    getResourceState,
    (state: RentalState) => state.add.currentStep,
);

const selectFormAddState = createSelector<RootState, RentalState, RentalAddState>(
    getResourceState,
    (state: RentalState) => state.add,
);

const selectFormAddStep1State = createSelector<RootState, RentalState, RentalAddStep1>(
    getResourceState,
    (state: RentalState) => state.add.step1,
);
const selectFormAddStep2State = createSelector<RootState, RentalState, RentalAddStep2>(
    getResourceState,
    (state: RentalState) => state.add.step2,
);

const selectFormAddStep3State = createSelector<RootState, RentalState, RentalAddStep3>(
    getResourceState,
    (state: RentalState) => state.add.step3,
);

const selectLastStep = createSelector<RootState, RentalState, boolean>(
    getResourceState,
    (state: RentalState) => state.add.lastStep,
);

const selectAdvancedSearchFilter = createSelector<RootState, RentalState, RentalAdvancedSearch>(
    getResourceState,
    (resourceState) => resourceState.advancedSearch,
);

const selectListTab = createSelector<RootState, RentalState, number>(
    getResourceState,
    (state: RentalState) => state.listTab,
);

const extraSelectors = {
    selectAdvancedSearchFilter,
    selectFormAddStep,
    selectFormAddState,
    selectFormAddStep1State,
    selectFormAddStep2State,
    selectFormAddStep3State,
    selectLastStep,
    selectListTab,
};

const selectors = { ...createResourceListSelectors(getResourceState), ...extraSelectors };

export default selectors;
